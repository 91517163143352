.App {
background-color:#C25171;
  
  min-height: 100vh;
}

.container{display: flex; background-color:#C25171;padding-left: 100px; flex-direction: column; align-items:left ; justify-content: center; font-size: calc(20px + 2vmin); min-height: 100vh;
  }


.container h1{color: #BEF5BC; font-weight: 300;}
.container p{font-size: calc(10px + 2vmin); margin-top: 0;color: #BEF5BC;}
.intro{color: #BEF5BC;font-weight: 100;text-align: left;}



.nav{
list-style: none;
width: 500px;
display: flex;
justify-content: space-evenly;

}

.MuiAppBar-colorPrimary{ background-color:#C25171 !important; }
.header{backgroundColor:#C25171; paddingRight: 79px ;paddingLeft: 118px;}
.menuButton{
      fontFamily: Open Sans, sans-serif;
      fontWeight: 700;
      size: 18px;
      marginLeft: 38px;
   }
     .Toolbar{
    display: flex;
    justifyContent: space-between;
  }
   
